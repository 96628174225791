import React from "react";
import { useNavigate } from "react-router-dom";
import firstFile from "../docs/Пользовательское соглашение.pdf";
import secondFile from "../docs/Согласие на обработку персональных данных.pdf";
import thirdFile from "../docs/Соглашение о защите от копирования и распространения информации.pdf";

function Footer() {
  const navigate = useNavigate();

  function goMain() {
    navigate("/");
  }

  return (
    <div className="footer">
      <div className="logoBox">
        <div className="logo logoFooter" onClick={goMain}>
          СОЦИАЛЬНЫЙ СКОРИНГ "МЫ"
        </div>
      </div>
      <div className="footerContent">
        {" "}
        <a className="footerButton" href={firstFile}>
          Пользовательское соглашение
        </a>
        <a className="footerButton" href={secondFile}>
          Согласие на обработку персональных данных
        </a>
        <a className="footerButton" href={thirdFile}>
          Соглашение о защите от копирования и распространения информации
        </a>
        <a
          className="footerButton"
          href="https://rgsu.net/netcat_files/Documents/processing-policy/%D0%9F%D0%9E%D0%9B%D0%9E%D0%96%D0%95%D0%9D%D0%98%D0%95%20%D0%BE%D0%B1%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B5%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85%20(%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B0%20%D0%B2%20%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85).pdf"
        >
          ПОЛОЖЕНИЕ об обработке персональных данных <br></br>(Политика Оператора в отношении обработки персональных данных) 
        </a>
      </div>
      <div className="ver">ver 0.03</div>
    </div>
  );
}

export default Footer;
